
import { connect } from 'react-redux';
import MobileAlertsSettings from '../../components/MobileAlertsSettings/MobileAlertsSettings';
import _ from 'lodash';
import {
  filterAlerts,
  sortAlertsLocally,
  setSelectedMobileAlert
} from '../../actions/MobileAlertsActions';

import { getSamplesAction, setSamplesFilters } from "../../actions/actions";

const mapStateToProps = (state, ownProps) => {
  const { selectedProject } = state.leaksList;
  const sampleStateOptions = state.leaksList.optionList.options.SampleState;
  const mobileState = state.mobile;
  const alertsList = mobileState.alerts.items;
  const sort = mobileState.alerts.mobileAlertsSort;
  const samplesFiletrs = mobileState.samples.filters;
  // const filters = mobileState.alerts.mobileAlertsFilters.filters;

  const samplesStatusFilters = samplesFiletrs
    .filter((f) => f.field === 'sample_state')
    .reduce((obj, current) => obj[current.field] = current.value, {});

  return {
    selectedProject,
    sampleStateOptions,
    alertsList,
    initialValues: {
      Alerts: {
        sortBy: _.upperFirst(_.camelCase(sort.field)),
        sortByDir: sort.dir,
        'CreationDate-From': mobileState.alerts.mobileAlertsFilters.defFilters['CreationDate-From'],
        'CreationDate-To': mobileState.alerts.mobileAlertsFilters.defFilters['CreationDate-To'],
        TaskName: mobileState.alerts.mobileAlertsFilters.defFilters.TaskName, // Add this line
      },
      Samples: {
        ['sample_state']: samplesStatusFilters
      }
    },
    ...ownProps
  }
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onSubmit: (values, dispatch, props) => {
    const { processFilters } = props;
    // console.log('values', values);

    const alertsFilters = processFilters({ ...values.Alerts });
    const samplesFilters = processFilters({ ...values.Samples });

    dispatch(setSelectedMobileAlert(null));
    dispatch(getSamplesAction(true, [], {}));
    dispatch(filterAlerts(alertsFilters));
    dispatch(
      sortAlertsLocally(_.snakeCase(alertsFilters.sort.field), alertsFilters.sort.dir)
    );

    dispatch(setSamplesFilters(samplesFilters.filters));
  },
});

const CMobileAlertsSettings = connect(
  mapStateToProps,
  mapDispatchToProps
)(MobileAlertsSettings);

export default CMobileAlertsSettings;
