/* eslint-disable react/jsx-pascal-case */
/* eslint-disable react/no-string-refs */
/* eslint-disable react/no-deprecated */
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Favicon from 'react-favicon';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import { reset, submit } from 'redux-form';

import PopupAlert from '../../components/Notifications/PopupAlert';
// import keydown from 'react-keydown';
import { SYSTEM_RESOURCE } from '../../constants/EndPoints';
import { NOISE_ALERTS_COUNT } from '../../constants/Misc';
import {
  FILTERS_ALERTS,
  FILTERS_COUPLES,
  FILTERS_MOBILE_TASKS,
  FILTERS_MOBILE_ALERTS,
  FILTERS_NOISE_ALERTS,
  FILTERS_SENSORS,
  FILTERS_SOPS,
  FILTERS_VALVE_ALERTS,
} from '../../constants/SettingsKeys';
import { getUIinfo } from '../UIhelper/UIhelper';

import { CMainMap } from '../../containers/CMap/CMainMap';
import CPCA from '../../containers/CPCA/CPCA';

import * as actionsAlerts from '../../actions/AlertsActions';
import actionsAlertsValve, { setDefFilters, setFilters } from '../../actions/AlertsValveActions';
import * as actionsAssessments from '../../actions/AssessmentsActions';
import * as actionsCouples from '../../actions/CouplesActions';
import * as actionsFiles from '../../actions/FilesActions';
import * as actionsGlobals from '../../actions/GlobalsActions';
import * as actionsInspect from '../../actions/InspectActions';
import * as actionsInterferences from '../../actions/InterferencesActions';
import { doSignOut } from '../../actions/LoginActions';
import * as actionsUsers from '../../actions/ManageActions';
import * as actionsMobileDevices from '../../actions/MobileDevicesActions';
import * as actionsMobileSamples from '../../actions/MobileSamplesActions';
import { setIQ100BReportsModalVisibility } from '../../actions/MobileStateAction';
import * as actionsMobileTasks from '../../actions/MobileTasksActions';
import * as actionsNoisesAlerts from '../../actions/NoiseAlertsActions';
import * as actionsNoises from '../../actions/NoiseSamplesActions';
import * as actionsPlanning from '../../actions/PlanningActions';
import * as actionsProjects from '../../actions/ProjectsActions';
import * as actionsPrsAlerts from '../../actions/PrsTransientAlertsActions';
import * as actionsPrsTranAlerts from '../../actions/PrsTransientAlertsActions';
import * as actionsSOPs from '../../actions/SOPsActions';
import * as actionsSamples from '../../actions/SamplesActions';
import { searchItem } from '../../actions/SearchActions';
import * as actionsSensors from '../../actions/SensorsActions';
import {
  sensorGraphesToggle,
  setCoverageSource,
  setPipesSource,
} from '../../actions/SettingsActions';
import {
  downloadFileFromURl,
  selectFeature,
  sortSamples,
} from '../../actions/selectors';
import {
  clearCouplePath,
  clearManageItem,
  createNewCouple,
  invalidateProject,
  setMapDrawMode,
} from '../../actions/setters';
import Header from '../../components/Header/Header';
import IQ100BReportsModal from '../../components/Modals/IQ100BReportsModal';
import SensorsActivityModal from '../../components/Modals/SensorsActivityModal';
import SensorsTechnicianModal from '../../components/Modals/SensorsTechnicianModal';
import StatusModal from '../../components/Modals/StatusModal';
import Picker from '../../components/Picker/Picker';
import LeakGrowthModal from '../../components/Technition/LeakGrowthModal';
import GoogleSheetsContext from '../../contexts/GoogleSheetProvider/googleSheetsContext';
import { CTableSwitch } from '../CTableSwitch/CTableSwitch';
import { CTabsSwitch } from '../CTabsSwitch/CTabsSwitch';
import CreateMobileAlertModal from "../../components/Modals/CreateMobileAlertModal";
import * as actionsMobileAlerts from "../../actions/MobileAlertsActions";

require('./Page.scss');

const _ = require('lodash');
const classNames = require('classnames');

class Page extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleRefreshClick = this.handleRefreshClick.bind(this);
    this.handlePipesSourceChange = this.handlePipesSourceChange.bind(this);
    this.handleCoverageSourceChange =
      this.handleCoverageSourceChange.bind(this);
    this.navigateToFeature = this.navigateToFeature.bind(this);
    this.selectSample = this.selectSample.bind(this);
    this.selectCoupleSample = this.selectCoupleSample.bind(this);
    this.handleUploadFile = this.handleUploadFile.bind(this);
    this.handleDownloadFile = this.handleDownloadFile.bind(this);
    // this.downloadFileFromURl = this.downloadFileFromURl.bind(this);
    this.handleDeleteFile = this.handleDeleteFile.bind(this);
    this.reloadProject = this.reloadProject.bind(this);
    this.toggleDetailsVisibility = this.toggleDetailsVisibility.bind(this);
    this.clearSelectedFeature = this.clearSelectedFeature.bind(this);
    this.closeMapDrawMode = this.closeMapDrawMode.bind(this);
    this.updateUIinfo = this.updateUIinfo.bind(this);
    //this.sortTable = this.sortTable.bind(this);
    this.sortTableLocaly = this.sortTableLocaly.bind(this);
    this.searchItem = this.searchItem.bind(this);
    this.sortSamples = this.sortSamples.bind(this);
    this.sortCoupleSamples = this.sortCoupleSamples.bind(this);
    this.removeFilter = this.removeFilter.bind(this);
    this.removeSensorsFilter = this.removeSensorsFilter.bind(this);
    this.removeSopsFilter = this.removeSopsFilter.bind(this);
    this.removeCouplesFilter = this.removeCouplesFilter.bind(this);
    this.removeMobileTasksFilter = this.removeMobileTasksFilter.bind(this);
    this.removeMobileAlertsFilter = this.removeMobileAlertsFilter.bind(this);
    this.removeLeaksFilter = this.removeLeaksFilter.bind(this);
    this.removeNoiseAlertsFilter = this.removeNoiseAlertsFilter.bind(this);
    this.removeValveAlertsFilter = this.removeValveAlertsFilter.bind(this);
    this.onPageIndexChange = this.onPageIndexChange.bind(this);
    this.getRelatedCoupleByAlertId = this.getRelatedCoupleByAlertId.bind(this);
    this.onCreateNewValue = this.onCreateNewValue.bind(this);
    this.handleDeleteCouple = this.handleDeleteCouple.bind(this);
    this.checkIfAlertExist = this.checkIfAlertExist.bind(this);
    this.handleAssessmentButtonClick =
      this.handleAssessmentButtonClick.bind(this);
    this.reloadFiles = this.reloadFiles.bind(this);
    this.reloadNoiseSamples = this.reloadNoiseSamples.bind(this);
    this.closeNoise = this.closeNoise.bind(this);
    this.sensorsGraphesToggle = this.sensorsGraphesToggle.bind(this);
    this.updateMapCenterLatLng = this.updateMapCenterLatLng.bind(this);
    this.setProjectCenter = this.setProjectCenter.bind(this);
    this.notify = this.notify.bind(this);
    this.addNewManageItem = this.addNewManageItem.bind(this);
    this.onChangeAlertsContext = this.onChangeAlertsContext.bind(this);
    this.onChangeManageContext = this.onChangeManageContext.bind(this);
    this.onChangeMobileContext = this.onChangeMobileContext.bind(this);
    this.updateProjectPipes = this.updateProjectPipes.bind(this);
    this.openNewCoupleForm = this.openNewCoupleForm.bind(this);
    this.selectSensorLog = this.selectSensorLog.bind(this);
    this.selectSensorSample = this.selectSensorSample.bind(this);
    this.selectSensorBit = this.selectSensorBit.bind(this);
    this.onPathChange = this.onPathChange.bind(this);
    this.closePCADialog = this.closePCADialog.bind(this);
    this.openStatusDialog = this.openStatusDialog.bind(this);
    this.getCalculateTotalPipes = this.getCalculateTotalPipes.bind(this);
    this.exportPipesReport = this.exportPipesReport.bind(this);
    this.exportAlertInvestigation = this.exportAlertInvestigation.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.createMobileAlertModalResetForm = this.createMobileAlertModalResetForm.bind(this);
    this.clearForm = this.clearForm.bind(this);
    this.updateLeakFromDialog = this.updateLeakFromDialog.bind(this);
    this.updateMobileAlertFromDialog = this.updateMobileAlertFromDialog.bind(this);
    this.statusModalEditComment = this.statusModalEditComment.bind(this);
    this.statusModalCopyComment = this.statusModalCopyComment.bind(this);
    this.statusModalResetForm = this.statusModalResetForm.bind(this);
    this.closeSensorsActivityModal = this.closeSensorsActivityModal.bind(this);
    this.closeSensorsTechnicianModal =
      this.closeSensorsTechnicianModal.bind(this);
    this.closeIQ100BReportsModal = this.closeIQ100BReportsModal.bind(this);

    this.state = {
      isAddMobileAlertOpen: false,
      mapCenter: [],
      uiInfo: getUIinfo({ minWidth: 1400 }),
      showDetails: false,
      sortBy: 'priority',
      sortDir: '',
      planningMode: null,
      popupAlert: {
        popupKey: -1,
        message: '',
        severity: 'info',
        action: null,
      },
      testDialogStatus: false,
    };
  }

  //@keydown('ctrl+f')
  //autocomplete(event) {
  //    //this.textInput.focus();
  //    return false;
  //}

  componentDidMount() {
    window.addEventListener('resize', this.updateUIinfo);
    this.notificationSystem = this.refs.notificationSystem;
  }

  componentWillReceiveProps(nextProps) {
    const { dispatch, path } = nextProps;

    if (this.props.path != path) {
      this.toggleDetailsVisibility(false);
      if (this.props.displayAssessments) {
        dispatch(actionsAssessments.displayAssessments(false));
      }
    }

    if (this.props.path === 'g5Devices') {
      this.reloadProject();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateUIinfo);
  }

  selectSensorLog(selectedSensorLog) {
    this.props.dispatch(actionsSensors.selectSensorLog(selectedSensorLog));
    if (!_.isEmpty(this.props.selectedSensorBit)) {
      this.selectSensorBit({});
    }
  }

  selectSensorSample(selectedSensorSample) {
    const { dispatch, selectedProject } = this.props;
    dispatch(
      actionsSensors.selectSensorSample(selectedProject, selectedSensorSample)
    );
  }

  selectSensorBit(selectedSensorBit) {
    this.props.dispatch(actionsSensors.selectSensorBit(selectedSensorBit));
    if (!_.isEmpty(this.props.selectedSensorLog)) {
      this.selectSensorLog({});
    }
  }

  signOutFunction = () => {
    this.props.dispatch(doSignOut());
  };
  // Notifications
  // @message	string  Message of the notification
  // @level	  string	Level of the notification. (success/error/warning/info)
  notify(message, level, action) {
    // this.notificationSystem.addNotification({
    //   message: message,
    //   level: level || 'success',
    //   position: 'tc',
    //   action: action,
    // });

    this.setState({
      popupAlert: {
        popupKey: new Date().getTime(),
        message: message,
        severity: level,
        action: action,
      },
    });
  }

  updateUIinfo() {
    this.setState({
      uiInfo: getUIinfo({ minWidth: 1400 }),
    });
  }

  handleChange(nextProject) {
    const { dispatch, filters, path } = this.props;
    //dispatch(actionsSensors.setSensorsDefFilters(Object.assign({}, { Sensors: { sortBy: 'id', sortByDir: 'asc', SensorStatus: ['Ready', 'Active', 'Non Active'] } }, filters.sensorsDefFilters.Sensors)));
    //dispatch(actionsSensors.sortSensors('id', 'asc'));
    dispatch(actionsProjects.selectProject(nextProject));
    //dispatch(actionsAssessments.displayAssessments(false));
  }

  onCreateNewValue(valueKey, value) {
    this.props.dispatch(actionsAlerts.addLeakOption(valueKey, value));
  }

  createMobileAlertModalResetForm() {
    this.props.dispatch(reset('add-mobile-alert'));
  };
  handleDeleteCouple(coupleID) {
    const { dispatch, selectedProject } = this.props;
    const self = this;
    dispatch(
      actionsCouples.deleteCouple(selectedProject, coupleID, (status, err) => {
        let message;
        let type = 'info';
        if (status) {
          message = self.context.t('Couple Deleted Successfuly');
        } else {
          type = 'error';
          if (err.message != null) {
            message = err.message;
          } else {
            message = self.context.t('Delete Failed');
          }
        }
        self.notify(message, type);
        if (status) {
          dispatch(selectFeature(selectedProject, '', ''));
          dispatch(actionsCouples.fetchCouplesIfNeeded(selectedProject));
        }
      })
    );
  }

  statusModalEditComment() {
    const { dispatch } = this.props;
    dispatch(actionsGlobals.editComment('change-status', 'comment'));
  }

  statusModalCopyComment() {
    const { dispatch } = this.props;
    dispatch(actionsGlobals.copyComment('change-status', 'comment'));
  }

  statusModalResetForm() {
    const { dispatch } = this.props;
    dispatch(reset('change-status'));
  }

  handleDownloadFile(filePath, usingType) {
    const { dispatch, selectedProject } = this.props;

    if (usingType !== 'upload') {
      this.notify(
        this.context.t(
          'preparing_to_download_if_download_does_not_commence_please_allow_popups_for_this_site'
        ),
        'info'
      );
    }
    dispatch(
      actionsFiles.getUrlForDownloadFile(
        selectedProject,
        filePath,
        usingType,
        '0',
        (res) => {
          if (!res.status) {
            this.notify(
              this.context.t('download_failed_file_not_found'),
              'error'
            );
          } else {
            //window.location.href = res.data;

            downloadFileFromURl(res.data);
          }
        }
      )
    );
  }

  // downloadFileFromURl(url) {
  //   // script that download file from url:
  //   if (url) {
  //     const tempElement = document.createElement('a');
  //     tempElement.href = url;
  //     tempElement.target = '_blank';
  //     tempElement.download = url.substring(
  //       url.lastIndexOf('/') + 1,
  //       url.lastIndexOf('?')
  //     );
  //     document.body.appendChild(tempElement);
  //     tempElement.click();
  //     setTimeout(() => {
  //       document.body.removeChild(tempElement);
  //     }, 100); // Adjust the delay as needed
  //   }
  // }

  handleUploadFile(file, cb) {
    //event.preventDefault();
    const { dispatch, selectedProject, selectedFeature } = this.props;
    dispatch(
      actionsFiles.getUrlForDownloadFile(
        selectedProject,
        file.name,
        'upload',
        selectedFeature,
        cb
      )
    );
  }

  handleDeleteFile(file) {
    this.props.dispatch(actionsFiles.deleteFile(file));
  }

  checkIfAlertExist(id) {
    const { alertsIndexMap } = this.props;
    const exist = alertsIndexMap[id] != null;
    return exist;
  }

  async navigateToFeature(featureId, selectedFeature) {
    const { dispatch, selectedProject } = this.props;
    let item;
    let bHaveToRouteChange = false;
    let path;

    switch (selectedFeature.type) {
      case 'alert': {
        const { alertsIndexMap, leaks } = this.props;
        bHaveToRouteChange = path !== 'alerts';
        path = 'alerts';
        // if (this.checkIfAlertExist(selectedFeature.id) == false) {
        // const self = this;
        // this.notify(
        //   this.context.t('remove_filters_to_view_item_location', {
        //     item: this.context.t('alert'),
        //   }),
        //   'error',
        //   {
        //     label: this.context.t('Clear All Filters'),
        //     callback: () => {
        //       this.removeLeaksFilter(dispatch, {}, [], selectedProject).then(
        //         () => {
        //           const { alertsIndexMap, leaks } = self.props;
        //           const alertObj = leaks[alertsIndexMap[featureId]];
        //           dispatch(actionsGlobals.setSelectedItem(alertObj));
        //         }
        //       );
        //     },
        //   }
        // );
        // }
        item = leaks[alertsIndexMap[featureId]];
        break;
      }
      case 'sensor':
      case 'alertCoupleSensor': {
        const {
          sensors: { sensors, indexMap },
        } = this.props;
        bHaveToRouteChange = path != 'sensors';
        path = 'sensors';
        item = sensors[indexMap[featureId]];
        break;
      }
      case 'couplePathLine': {
        const {
          couples: { couples, indexMap },
        } = this.props;

        bHaveToRouteChange = path != 'couples';
        path = 'couples';
        item = couples[indexMap[featureId]];
        break;
      }
      default:
        break;
    }

    if (bHaveToRouteChange) {
      await browserHistory.push('/' + path + '?project=' + selectedProject);
      // console.log('change path to: ' + path + '?project=' + selectedProject);
    }
    if (item != null) {
      dispatch(actionsPrsTranAlerts.changeAlertsContext('alerts'));
      dispatch(actionsGlobals.setSelectedItem(item));
    } else {
      this.searchItem(selectedFeature.id, 'ID');
    }
  }

  getRelatedCoupleByAlertId(alertID, alerts, alertsIndex) {
    const alert = alerts[alertsIndex[alertID]];
    const coupleID = alert == null ? '' : alert.CoupleID;
    return coupleID;
  }

  selectSample(sample) {
    const { dispatch, selectedFeature, selectedProject } = this.props;
    dispatch(
      actionsSamples.selectSample(selectedProject, selectedFeature, sample)
    );
  }

  selectCoupleSample(coupleSample) {
    const { dispatch, selectedProject } = this.props;
    dispatch(actionsCouples.selectCoupleSample(selectedProject, coupleSample));
  }

  reloadProject() {
    const currentProject = this.props.selectedProject;
    this.props.dispatch(actionsProjects.selectProject(currentProject));
  }

  toggleDetailsVisibility(show) {
    if (typeof show == 'undefined') {
      // If not explicit - simple toggle.
      show = this.state.showDetails ? false : true;
    }

    this.setState({
      showDetails: show,
    });
  }

  openNewCoupleForm(active) {
    const { dispatch } = this.props;
    dispatch(clearCouplePath());
    dispatch(createNewCouple(active));
    if (active) {
      const materialsList = this.props.optionList.options.Materials;
      const defaultMaterial = materialsList.find(
        (material) => material.label == 'Metal'
      );
      dispatch(actionsPlanning.setupSensors(true));
      if (defaultMaterial != null) {
        dispatch(
          actionsGlobals.initializeForm('coupleDetails', {
            MaterialCode: defaultMaterial.value,
            VelocityMS: defaultMaterial.velocity,
          })
        );
      }
    }
  }

  clearSelectedFeature() {
    const { dispatch, path } = this.props;

    if (path === 'manage') {
      const { selectedManageItem } = this.props;
      if (selectedManageItem != null) {
        dispatch(clearManageItem());
        dispatch(setMapDrawMode(false));
      }
    } else if (
      (path === 'alerts' && this.props.alertsContext === 'noise') ||
      (path === 'alerts' && this.props.alertsContext === 'valve')
    ) {
      switch (this.props.alertsContext) {
        case 'noise':
          dispatch(actionsNoisesAlerts.selectNoiseAlert({}));
          break;
        case 'valve':
          dispatch(actionsAlertsValve.selectValveAlert({}));
      }
    } else if (path === 'mobile') {
      switch (this.props.mobileContext) {
        case 'Tasks': {
          dispatch(actionsMobileTasks.setSelectedTask([]));
          dispatch(actionsMobileSamples.setSelectedSample({}));
          break;
        }
        case 'Alerts': {
          dispatch(actionsMobileAlerts.setSelectedMobileAlert(null));
          dispatch(actionsMobileSamples.setSelectedSample({}));
          break;
        }
        case 'Devices': {
          dispatch(actionsMobileDevices.selectMobileDevice({}));
          dispatch(actionsMobileDevices.getDeviceBistAction());
          break;
        }
      }
    } else {
      const { selectedProject } = this.props;
      if (selectedProject != null) {
        dispatch(selectFeature(selectedProject, '', '', null));
        if (path === 'couples') {
          const { planningState } = this.props;
          if (planningState.isActive) {
            dispatch(actionsPlanning.clearAll());
          }
        }
      }
    }
  }

  closeMapDrawMode() {
    this.props.dispatch(setMapDrawMode(false));
  }

  sortTableLocaly(field, dir) {
    const { dispatch, selectedProject, path, filters } = this.props;

    switch (path) {
      default:
      case 'alerts':
        //dispatch(actionsAlerts.sortLeaks(field, dir));
        //dispatch(actionsAlerts.setDefFilters(Object.assign({}, { sortBy: field, sortByDir: dir }, filters.defFilters)));
        switch (this.props.alertsContext) {
          case 'noise':
            dispatch(actionsNoisesAlerts.sortNoiseAlerts(field, dir));
            break;
          case 'valve':
            dispatch(actionsAlertsValve.sortValveAlerts(field, dir));
            break;

          default:
            break;
        }
        break;
      case 'sensors':
        dispatch(actionsSensors.sortSensorsLocaly(selectedProject, field, dir));
        //dispatch(actionsSensors.setSensorsDefFilters(Object.assign({}, { Sensors: { sortBy: field, sortByDir: dir, SensorStatus: ['Ready', 'Active', 'Non Active'] } }, filters.sensorsDefFilters.Sensors)));
        break;
      case 'couples':
        dispatch(actionsCouples.sortCouples(field, dir));

        dispatch(
          actionsCouples.setCouplesDefFilters(
            Object.assign({}, { sortBy: field, sortByDir: dir })
          )
        );
        break;
      case 'manage':
        switch (this.props.managementContext) {
          case 'Customers':
            //indexMap = customers.indexMap || {};
            break;
          case 'Projects':
            //indexMap = projectsList.projectIndexMap || {};
            break;
          case 'Users':
            dispatch(actionsUsers.sortUsersLocaly(field, dir));
            dispatch(
              actionsUsers.setUsersDefFilters(
                Object.assign({}, { field: field, dir: dir })
              )
            );
            break;
        }
        break;

      case 'install': {
        switch (this.props.installContext) {
          case 'Interferences':
            dispatch(actionsInterferences.sortInterferencesLocaly(field, dir));
            break;

          default:
            break;
        }
        break;
      }
      case 'mobile':
        switch (this.props.mobileContext) {
          case 'Tasks':
            dispatch(actionsMobileTasks.sortTasksLocally(_.snakeCase(field), dir));
            break;
          case 'Alerts':
            dispatch(actionsMobileAlerts.sortAlertsLocally(_.snakeCase(field), dir));
            break;
          // case 'Devices':
          //   dispatch(actionsMobileDevices.sortDevicesLocally(_.snakeCase(field), dir));
          //   break;
        }
    }
  }

  searchItem(itemID, type) {
    const self = this;
    const { dispatch, path, selectedProject } = this.props;

    dispatch(
      searchItem(itemID, type, path, (featureIndex, serverResult) => {
        if (featureIndex != null) {
          //self.handleRowClick(featureIndex, null, 'searchItem');
        } else {
          let message;
          let msgType;
          let action;
          let itemType;
          switch (path) {
            case 'alerts':
              itemType = self.context.t('alert');
              break;
            case 'sensors':
              itemType = self.context.t('sensor');
              break;
            case 'couples':
              itemType = self.context.t('couple');
              break;
            case 'install':
              if (this.props.installContext == 'Sensors') {
                itemType = self.context.t('sensor');
              } else {
                itemType = self.context.t('installation point');
              }
            default:
              break;
          }

          if (serverResult == null || serverResult.status == false) {
            // console.log('search failed. result: ' + serverResult);
            msgType = 'error';
            message = self.context.t('cannot_find_your_search');
          } else {

            msgType = 'info';
            if (serverResult.projectID == null) {
              message = self.context.t('not_found');
            } else {
              if (serverResult.projectID == selectedProject) {
                message = self.context.t(
                  'item_is_hidden_by_filters',
                  { item: itemType }
                );
                action = {
                  label: self.context.t('display_item', { item: itemType }),
                  callback: () => {
                    dispatch(actionsGlobals.addExceptionalItem(serverResult.item));
                  },
                };
              } else {
                // console.log('serverResult', serverResult);
                message = self.context.t('your_item_located_in_project', {
                  item: itemType,
                  project: serverResult.projectName,
                });
                action = {
                  label: self.context.t('switch_to_project', {
                    project: serverResult.projectName,
                  }),
                  callback: () => {
                    dispatch(
                      actionsProjects.selectProject(
                        serverResult.projectID.toString()
                      )
                    );
                  },
                };
              }
            }
          }
          self.notify(message, msgType, action);
        }
      })
    );
  }

  sortSamples(field, dir) {
    const { dispatch, selectedProject, selectedFeature } = this.props;
    const relatedCouple = this.getRelatedCoupleByAlertId(
      selectedFeature,
      this.props.leaks,
      this.props.indexMap
    );
    //const pageIndex = (coupleSamples[selectedFeature] && coupleSamples[selectedFeature].pageIndex) || 0;
    dispatch(sortSamples(field, dir));
    dispatch(
      actionsSamples.fetchSamplesIfNeeded(
        selectedProject,
        selectedFeature,
        relatedCouple,
        0
      )
    );
  }

  sortCoupleSamples(field, dir) {
    // const { dispatch, selectedProject, selectedFeature, coupleSamples } = this.props;
    // const pageIndex = (coupleSamples[selectedFeature] && coupleSamples[selectedFeature].pageIndex) || 0;
    // dispatch(actionsCouples.sortCoupleSamples(field, dir));
    // dispatch(actionsCouples.fetchCoupleSamplesIfNeeded(selectedProject, selectedFeature, pageIndex));
  }

  removeFilter(filter) {
    const {
      dispatch,
      filters,
      selectedProject,
      path,
      installContext,
      alertsContext,
      valveFilters,
      valveDefFilters
    } = this.props;
    var defFilters;
    var oldFilters;
    var func;
    switch (path) {
      case 'alerts':
        if (alertsContext === 'noise') {
          defFilters = filters.noiseAlerts.defFilters;
          func = this.removeNoiseAlertsFilter;
          oldFilters = filters.noiseAlerts.filters;
        } else if (alertsContext === 'valve') {
          defFilters = valveDefFilters;
          func = this.removeValveAlertsFilter;
          oldFilters = valveFilters;
        } else {
          defFilters = filters.defFilters;
          func = this.removeLeaksFilter;
          oldFilters = filters.filters;
        }
        break;
      case 'sensors':
        defFilters = filters.sensorsDefFilters;
        func = this.removeSensorsFilter;
        oldFilters = filters.sensorsFilters;
        break;
      case 'couples':
        defFilters = filters.couples.defFilters;
        func = this.removeCouplesFilter;
        oldFilters = filters.couples.filters;
        break;
      case 'install':
        if (installContext == 'SOPs') {
          defFilters = filters.sops.defFilters;
          func = this.removeSopsFilter;
          oldFilters = filters.sops.filters;
        } else if (installContext == 'Sensors') {
          defFilters = filters.sensorsDefFilters;
          func = this.removeSensorsFilter;
          oldFilters = filters.sensorsFilters;
        }
        break;
      case 'mobile':
        switch (this.props.mobileContext) {
          case 'Tasks':
            defFilters =
              this.props.mobileState.tasks.mobileTasksFilters.defFilters;
            oldFilters =
              this.props.mobileState.tasks.mobileTasksFilters.filters;
            func = this.removeMobileTasksFilter;
            break;
          case 'Alerts':
            defFilters =
              this.props.mobileState.alerts.mobileAlertsFilters.defFilters;
            oldFilters =
              this.props.mobileState.alerts.mobileAlertsFilters.filters;
            func = this.removeMobileAlertsFilter;
            break;
        }
        break;

      default:
        break;
    }
    var newDefFilters = Object.assign({}, defFilters);
    var datesFields = [
      'DetectedAt',
      'fixDate',
      'ClosureDate',
      'SensorInstallationDate',
    ];

    if (datesFields.indexOf(filter) !== -1) {
      delete newDefFilters[`${filter}-To`];
      delete newDefFilters[`${filter}-From`];
    }
    delete newDefFilters[filter];
    // if (actionsGlobals.isDateField(filter)) {
    //   newDefFilters[filter + '-To'] = '';
    //   newDefFilters[filter + '-From'] = '';
    // }

    var newFilters = oldFilters.slice(0);

    newFilters = newFilters.filter(function (f) {
      return f.field !== filter;
    });
    func(dispatch, newDefFilters, newFilters, selectedProject);
  }

  removeLeaksFilter(dispatch, newDefFilters, newFilters, selectedProject) {
    dispatch(invalidateProject(selectedProject));
    dispatch(actionsAlerts.setDefFilters(newDefFilters));
    dispatch(actionsAlerts.setFilters(newFilters));
    localStorage.setItem(FILTERS_ALERTS, JSON.stringify(newDefFilters));
    return dispatch(actionsAlerts.fetchLeaksIfNeeded(selectedProject));
  }

  removeNoiseAlertsFilter(
    dispatch,
    newDefFilters,
    newFilters,
    selectedProject
  ) {
    dispatch(invalidateProject(selectedProject));
    dispatch(actionsNoisesAlerts.setDefFilters(newDefFilters));
    dispatch(actionsNoisesAlerts.setFilters(newFilters));
    dispatch(actionsNoisesAlerts.fetchNoiseAlerts(selectedProject));
    localStorage.setItem(FILTERS_NOISE_ALERTS, JSON.stringify(newDefFilters));
  }

  removeValveAlertsFilter(
    dispatch,
    newDefFilters,
    newFilters,
    selectedProject
  ) {
    dispatch(invalidateProject(selectedProject));
    dispatch(setDefFilters(newDefFilters));
    dispatch(setFilters(newFilters));
    dispatch(actionsAlertsValve.fetchValveAlerts(selectedProject));
    localStorage.setItem(FILTERS_VALVE_ALERTS, JSON.stringify(newDefFilters));
  }

  removeSensorsFilter(dispatch, newDefFilters, newFilters, selectedProject) {
    dispatch(actionsSensors.setSensorsDefFilters(newDefFilters));
    dispatch(actionsSensors.setSensorsFilters(newFilters));
    dispatch(actionsSensors.fetchSensorsIfNeeded(selectedProject));
    localStorage.setItem(FILTERS_SENSORS, JSON.stringify(newDefFilters));
  }

  removeSopsFilter(dispatch, newDefFilters, newFilters, selectedProject) {
    dispatch(actionsSOPs.setSopsDefFilters(newDefFilters));
    dispatch(actionsSOPs.setSopsFilters(newFilters));
    dispatch(actionsSOPs.fetchSOPsIfNeeded(selectedProject));
    localStorage.setItem(FILTERS_SOPS, JSON.stringify(newDefFilters));
  }

  removeCouplesFilter(dispatch, newDefFilters, newFilters, selectedProject) {
    dispatch(actionsCouples.setCouplesDefFilters(newDefFilters));
    dispatch(actionsCouples.setCouplesFilters(newFilters));
    dispatch(actionsCouples.fetchCouplesIfNeeded(selectedProject));
    localStorage.setItem(FILTERS_COUPLES, JSON.stringify(newDefFilters));
  }

  removeMobileTasksFilter(
    dispatch,
    newDefFilters,
    newFilters,
    selectedProject
  ) {
    dispatch(
      actionsMobileTasks.filterTasks({
        defFilters: newDefFilters,
        filters: newFilters,
      })
    );
    dispatch(reset('mobile-tasks-settings'));
    localStorage.setItem(FILTERS_MOBILE_TASKS, JSON.stringify(newDefFilters));
  }

  removeMobileAlertsFilter(
    dispatch,
    newDefFilters,
    newFilters,
    selectedProject
  ) {
    dispatch(
      actionsMobileAlerts.filterAlerts({
        defFilters: newDefFilters,
        filters: newFilters,
      })
    );
    dispatch(reset('mobile-alerts-settings'));
    localStorage.setItem(FILTERS_MOBILE_ALERTS, JSON.stringify(newDefFilters));
  }

  handleRefreshClick(e) {
    e.preventDefault();

    const { dispatch, selectedProject } = this.props;
    dispatch(invalidateProject(selectedProject));
    dispatch(actionsAlerts.fetchLeaksIfNeeded(selectedProject));
  }

  handlePipesSourceChange(e, source) {
    const { dispatch } = this.props;

    dispatch(setPipesSource(source));
  }

  handleCoverageSourceChange(e, source) {
    const { dispatch } = this.props;

    dispatch(setCoverageSource(source));
  }

  onPageIndexChange(newIndex, callerName) {
    const { selectedProject, selectedFeature, relatedItem, dispatch } =
      this.props;
    switch (this.props.path) {
      case 'alerts':
        const { leaks, alertsIndexMap } = this.props;
        const selectedAlert = leaks[alertsIndexMap[selectedFeature]];

        // dispatch(actionsSamples.selectSample(undefined));
        // dispatch(actionsSamples.fetchSamplesIfNeeded(selectedProject, selectedFeature, selectedAlert.CoupleID, newIndex));
        dispatch(actionsCouples.selectCoupleSample(selectedProject, undefined));
        // dispatch(actionsCouples.fetchCoupleSamplesIfNeeded(selectedProject, selectedAlert.CoupleID, selectedAlert.ID, newIndex));
        switch (this.props.alertsContext) {
          case 'alerts':
            dispatch(
              actionsCouples.fetchCoupleSamplesIfNeeded(
                selectedProject,
                selectedAlert.CoupleID,
                newIndex
              )
            );
            break;

          case 'noise': {
            const noiseAlert = this.props.selectedNoiseAlert;
            if (!_.isEmpty(noiseAlert)) {
              const sensorId = noiseAlert.SensorID;
              dispatch(
                actionsSensors.fetchSensorNC(
                  selectedProject,
                  sensorId,
                  null,
                  newIndex
                )
              );
            }
            // const sensors = this.props.sensors;
            // const sensor = sensors.sensors[sensors.indexMap[selectedFeature]];
            // dispatch(actionsSensors.fetchSensorNC(selectedProject, sensor.id, sensor.SensorTypeValue, newIndex));
            break;
          }

          default:
            break;
        }

        break;
      case 'install':
      case 'sensors': {
        const sensors = this.props.sensors;
        const sensor = sensors.sensors[sensors.indexMap[selectedFeature]];
        switch (callerName) {
          case 'SensorSamplesTab':
            dispatch(
              actionsSensors.fetchSensorNC(
                selectedProject,
                sensor.id,
                sensor.SensorTypeValue,
                newIndex
              )
            );
            break;

          case 'SensorLogsTab':
            dispatch(
              actionsSensors.fetchSensorLogs(
                selectedProject,
                sensor.DeviceID,
                newIndex
              )
            );
            break;

          case 'SensorBitsTab':
            dispatch(
              actionsSensors.fetchSensorBits(
                selectedProject,
                sensor.DeviceID,
                newIndex
              )
            );
            break;

          default:
            break;
        }

        break;
      }
      case 'couples':
        dispatch(actionsCouples.selectCoupleSample(selectedProject, undefined));
        dispatch(
          actionsCouples.fetchCoupleSamplesIfNeeded(
            selectedProject,
            selectedFeature,
            newIndex
          )
        );
        break;
      default:
        break;
    }
  }

  handleAssessmentButtonClick(display) {
    const { dispatch } = this.props;
    dispatch(actionsAssessments.displayAssessments(display));
  }

  reloadFiles() {
    const { dispatch, selectedProject, selectedFeature } = this.props;
    dispatch(
      actionsFiles.fetchFilesIfNeeded(selectedProject, selectedFeature, true)
    );
  }

  reloadNoiseSamples(samplesCount) {
    const { dispatch, selectedProject, selectedFeature } = this.props;
    dispatch(
      actionsSensors.setNoiseSamplesCount(selectedProject, samplesCount)
    );
    const sensorId =
      this.props.path === 'sensors'
        ? selectedFeature
        : this.props.selectedNoiseAlert.SensorID;
    dispatch(actionsSensors.fetchSensorNC(selectedProject, sensorId, 0, 0));
  }

  closeNoise(sensorID, noiseAlertId) {
    const { dispatch } = this.props;
    dispatch(actionsNoises.closeNoiseAlert(sensorID, noiseAlertId));
  }

  sensorsGraphesToggle() {
    const { dispatch, sensorsGraphsDisplay } = this.props;
    dispatch(sensorGraphesToggle(!sensorsGraphsDisplay));
  }

  updateMapCenterLatLng(centerLatLng) {
    this.setState({
      mapCenter: centerLatLng,
    });
  }

  setProjectCenter() {
    const { dispatch, selectedManageItem } = this.props;
    dispatch(
      actionsProjects.setProjectCenter(
        selectedManageItem.id,
        this.state.mapCenter,
        (success) => {
          let level;
          let message;
          if (success) {
            level = 'success';
            message = this.context.t('success');
          } else {
            level = 'error';
            message = this.context.t('failed');
          }

          this.notify(message, level);
        }
      )
    );
  }

  onChangeAlertsContext(context) {
    const { dispatch, selectedProject } = this.props;
    dispatch(selectFeature(selectedProject, null));
    dispatch(actionsPrsAlerts.changeAlertsContext(context));
    this.toggleDetailsVisibility(false);
  }

  addNewManageItem() {
    const { dispatch } = this.props;
    dispatch(actionsGlobals.addNewManageItem());
    dispatch(setMapDrawMode(false));
    this.toggleDetailsVisibility(true);
  }

  onChangeManageContext(context) {
    const { dispatch } = this.props;
    // dispatch(actionsMapHandler.handleLayerChange("projectsCoverage", true));
    dispatch(setMapDrawMode(false));
    dispatch(actionsGlobals.changeManagementContext(context));
    this.toggleDetailsVisibility(false);
  }

  onChangeMobileContext(context) {
    // console.log(context);
  }

  updateProjectPipes() {
    const { dispatch, selectedManageItem } = this.props;
    dispatch(
      actionsProjects.updateProjectPipes(selectedManageItem.id, (success) => {
        let level;
        let message;
        if (success) {
          level = 'success';
          message = this.context.t('success');
        } else {
          level = 'error';
          message = this.context.t('failed');
        }

        this.notify(message, level);
      })
    );
  }

  onPathChange(nextPath) {
    const { dispatch, path } = this.props;
    dispatch(actionsGlobals.onPathChange(path, nextPath));
    if (nextPath == 'install') {
      if (!_.isEmpty(this.props.filters.sensorsFilters)) {
        dispatch(actionsSensors.setSensorsFilters([]));
        dispatch(
          actionsSensors.setSensorsDefFilters({
            sortBy: 'id',
            sortByDir: 'asc',
            SensorStatus: [],
          })
        );
        dispatch(
          actionsSensors.fetchSensorsIfNeeded(this.props.selectedProject, true)
        );
      }
    }
  }

  closePCADialog() {
    this.props.dispatch(actionsAlerts.setOpenPCA(false, false));
  }

  openStatusDialog(
    isOpen,
    id,
    stateName,
    value,
    prevState,
    nextState,
    rowIndex,
    field
  ) {
    this.props.dispatch(
      actionsAlerts.setOpenStatus(
        isOpen,
        id,
        stateName,
        value,
        prevState,
        nextState,
        rowIndex,
        field
      )
    );
  }

  // Updating the alert status from "StatusModal" dialog
  updateLeakFromDialog(data) {
    if (this.props.alertsContext === 'alerts') {
      this.props.dispatch(actionsAlerts.updateLeak(data));
    } else if (this.props.alertsContext === 'noise') {
      const noiseAlert = { ID: this.props.selectedFeature };
      data.forEach((d) => (noiseAlert[d.field] = d.value));

      this.props.dispatch(actionsNoisesAlerts.updateNoiseAlert(noiseAlert));
    } else if (this.props.alertsContext === 'valve') {
      const valveAlert = { ID: this.props.selectedFeature };
      data.forEach((d) => {

        valveAlert[d.field] = d.value;

      });

      if (valveAlert.alert_state === '5') {
        valveAlert.end_date = new Date();
      }

      this.props.dispatch(actionsAlertsValve.updateValveAlert(valveAlert));
    }
  }

  updateMobileAlertFromDialog(data) {
    this.props.dispatch(actionsMobileAlerts.updateMobileAlert(data));
  }

  submitForm(formName) {
    this.props.dispatch(submit(formName));
  }

  clearForm(formName) {
    this.props.dispatch(reset(formName));
  }

  getCalculateTotalPipes() {
    this.props.dispatch(
      actionsProjects.calcTotalPipesLength(this.props.selectedProject)
    );
  }

  exportPipesReport() {
    const { dispatch, selectedProject } = this.props;
    dispatch(actionsInspect.investigatePipes(selectedProject, 'xls'));
  }

  exportAlertInvestigation() {
    const { dispatch, selectedProject } = this.props;
    dispatch(actionsInspect.investigateAlerts(selectedProject, 'xls'));
  }

  getMapCaptureFunc = (impl) => {
    this.getMapCaptureImpl = impl;
  };

  setMapLayersDisplayFunc = (impl) => {
    this.setMapLayersDisplayImpl = impl;
  };

  closeSensorsActivityModal() {
    this.props.dispatch(
      actionsSensors.setSensorsActivityModalVisibility(false)
    );
  }
  closeSensorsTechnicianModal() {
    this.props.dispatch(
      actionsSensors.setSensorsTechnicianModalVisibility(false)
    );
  }

  closeIQ100BReportsModal() {
    this.props.dispatch(setIQ100BReportsModalVisibility(false));
  }

  getSensorsActivityData = (year, month, boundary, isIncludeArchived) => {
    this.props.dispatch(
      actionsSensors.getSensorsActivityData(
        year,
        month,
        boundary,
        isIncludeArchived
      )
    );
  };

  getTechnicianActivityData = (year, month, type) => {
    this.props.dispatch(
      actionsSensors.getTechnicianActivityData(year, month, type)
    );
  };

  render() {
    const {
      selectedProject,
      projectGraphsUnits,
      leaks,
      details,
      isFetching,
      selectedFeature,
      selectedFeatureType,
      relatedItem,
      iconFeatures,
      projects,
      optionList,
      history,
      sensors,
      sensorsHistory,
      sensorDetails,
      noiseAlertCount,
      sops,
      noiseSamples,
      iQuariusPublishedSamples,
      waterMeterAlerts,
      couplePath,
      linkedSensorsPaths,
      couplesCoveragePaths,
      couples,
      assessmentsAlerts,
      displayAssessments,
      leakCouple,
      filters,
      user,
      path,
      indexMap,
      alertsIndexMap,
      log,
      center,
      files,
      samples,
      coupleSamples,
      selectedSensorLog,
      selectedSensorBit,
      selectedSensorSample,
      selectedAssessment,
      distanceFactor,
      units,
      timeZone,
      pipesSource,
      coverageSource,
      sensorsGraphsDisplay,
      // projectName,
      samplesPage,
      selectedManageItem,
      managementContext,
      customers,
      users,
      logo,
      planningState,
      installContext,
      installMode,
      iQuariusSamples,
      selectedTaskId,
      totalNoiseSamplesCount,
      leakGrowthData,
    } = this.props;


    const { uiInfo, showDetails } = this.state;

    const mainClasses = classNames('main', { fetching: isFetching });

    const sys = SYSTEM_RESOURCE;

    return (
      <section className='page leaks-page'>
        <Favicon
          url={require('./../../../images/system/' + sys + '/favicon_1.png')}
        />

        <Header
          selectedProject={selectedProject}
          onPathChange={this.onPathChange}
          user={user}
          local={timeZone}
          downloadFile={this.handleDownloadFile}
          logo={logo}
          signOutFunc={this.signOutFunction}
        />

        <div className={mainClasses}>
          {
            //PICKER = Projects dropdown selecting
            path !== 'manage' && (
              <Picker
                value={selectedProject}
                onChange={this.handleChange}
                options={projects}
                isAqsAdmin={user.isAQS}
              />
            )
          }
          <CMainMap
            sensors={sensors}
            sensorDetails={sensorDetails}
            noiseAlertCount={noiseAlertCount}
            alertDetails={details}
            sops={sops}
            noiseSamples={noiseSamples}
            iQuariusPublishedSamples={iQuariusPublishedSamples}
            iQuariusSamples={iQuariusSamples}
            waterMeterLayer={waterMeterAlerts}
            couplePath={couplePath}
            linkedSensorsPaths={linkedSensorsPaths}
            couplesCoveragePaths={couplesCoveragePaths}
            iconFeatures={iconFeatures}
            assessments={assessmentsAlerts}
            displayAssessments={displayAssessments}
            uiInfo={uiInfo}
            selectedFeature={selectedFeature}
            selectedAssessment={selectedAssessment}
            selectedFeatureType={selectedFeatureType}
            relatedItem={relatedItem}
            leakCouple={leakCouple}
            filters={filters}
            center={center}
            path={path}
            selectedSensorLog={selectedSensorLog}
            selectedSensorBit={selectedSensorBit}
            selectSensorLog={this.selectSensorLog}
            selectSensorSample={this.selectSensorSample}
            selectSensorBit={this.selectSensorBit}
            selectSample={this.selectSample}
            selectCoupleSample={this.selectCoupleSample}
            selectedProject={selectedProject}
            showLayerManager={true}
            showLegend={true}
            showSearchBox={true}
            pipesSource={pipesSource}
            coverageSource={coverageSource}
            pipesSourceChange={this.handlePipesSourceChange}
            coverageSourceChange={this.handleCoverageSourceChange}
            sensorsGraphsDisplay={sensorsGraphsDisplay}
            sensorsGraphesToggle={this.sensorsGraphesToggle}
            reloadNoiseSamples={this.reloadNoiseSamples}
            closeNoise={this.closeNoise}
            timeZone={timeZone}
            graphsUnits={projectGraphsUnits}
            units={units}
            distanceFactor={distanceFactor}
            downloadFile={this.handleDownloadFile}
            downloadFileFromURl={downloadFileFromURl}
            user={user}
            viewWaterMeterLayer={user.viewWaterMeter}
            getMapCenterLatLng={this.updateMapCenterLatLng}
            planningMode={planningState}
            installMode={installMode}
            getMapCapture={this.getMapCaptureFunc}
            setMapLayersDisplay={this.setMapLayersDisplayFunc}
            notify={this.notify}
          />

          <CTableSwitch
            path={path}
            uiInfo={uiInfo}
            showDetails={showDetails}
            managementContext={managementContext}
            sortTableLocaly={this.sortTableLocaly}
            onSearchItem={this.searchItem}
            removeFilter={this.removeFilter}
            refreshData={this.reloadProject}
            selectSample={this.selectSample}
            toggleDetailsVisibility={this.toggleDetailsVisibility}
            handleAssessmentButtonClick={this.handleAssessmentButtonClick}
            notificationSystem={this.notificationSystem}
            addNewManageItem={this.addNewManageItem}
            onChangeAlertsContext={this.onChangeAlertsContext}
            onChangeManageContext={this.onChangeManageContext}
            onChangeMobileContext={this.onChangeMobileContext}
            clearSelectedFeature={this.clearSelectedFeature}
            openNewCoupleForm={this.openNewCoupleForm}
            installMode={installMode}
            mobileContext={this.props.mobileContext}
            getMapCapture={this.getMapCaptureImpl}
            setMapLayersDisplay={this.setMapLayersDisplayImpl}
            notify={this.notify}
          />
          <CTabsSwitch
            user={user}
            // projectName={projectName}
            path={path}
            alertsContextMenu={this.props.alertsContext}
            uiInfo={uiInfo}
            setIsAddMobileAlertOpen={(isOpen) => this.setState({ isAddMobileAlertOpen: isOpen })}
            selectedProject={selectedProject}
            selectedFeature={selectedFeature}
            featureType={selectedFeatureType}
            downloadFile={this.handleDownloadFile}
            onPageIndexChange={this.onPageIndexChange}
            onCreateNewValue={this.onCreateNewValue}
            showDetails={showDetails}
            toggleDetailsVisibility={this.toggleDetailsVisibility}
            clearSelectedFeature={this.clearSelectedFeature}
            indexMap={indexMap}
            log={log}
            uploadFile={this.handleUploadFile}
            deleteFile={this.handleDeleteFile}
            samples={samples}
            selectSample={this.selectSample}
            selectCoupleSample={this.selectCoupleSample}
            selectSensorLog={this.selectSensorLog}
            selectSensorSample={this.selectSensorSample}
            selectSensorBit={this.selectSensorBit}
            selectedSensorLog={selectedSensorLog}
            selectedSensorBit={selectedSensorBit}
            selectedSensorSample={selectedSensorSample}
            sortSamples={this.sortSamples}
            distanceFactor={distanceFactor}
            timeZone={timeZone}
            coupleSamples={coupleSamples}
            sortCoupleSamples={this.sortCoupleSamples}
            handleDeleteCouple={this.handleDeleteCouple}
            displayAssessments={displayAssessments}
            selectedAssessment={selectedAssessment}
            navigateToFeature={this.navigateToFeature}
            manageItem={selectedManageItem}
            setProjectCenter={this.setProjectCenter}
            notify={this.notify}
            managementContext={managementContext}
            updateProjectPipes={this.updateProjectPipes}
            planningState={planningState}
            installContext={installContext}
            installMode={installMode}
            closeMapDrawMode={this.closeMapDrawMode}
            iQuariusSamples={iQuariusSamples}
            selectedTaskId={selectedTaskId}
            calculatedTotalLengthTable={this.props.calculatedTotalLengthTable}
            getCalculateTotalPipes={this.getCalculateTotalPipes}
            exportPipesReport={this.exportPipesReport}
            exportAlertInvestigation={this.exportAlertInvestigation}
            units={units}
            clearForm={this.clearForm}
            totalNoiseSamplesCount={totalNoiseSamplesCount}
            noiseSamplesRequestedCount={noiseAlertCount}
            openNewCoupleForm={this.openNewCoupleForm}
            getMapCapture={this.getMapCaptureImpl}
          />

          <CPCA
            isOpen={this.props.isPCA}
            closePCADialog={this.closePCADialog}
            submitAction={this.submitForm}
            notify={this.notify}
            pcaItem={this.props.pcaItem}
          />

          <LeakGrowthModal
            isOpen={this.props.pcaLeakGrowthOpen}
            alertId={this.props.openAlertGrowthId}
            data={leakGrowthData}
            onClose={() =>
              this.props.dispatch(
                actionsAlerts.setOpenPcaLeakGrowth(null, false)
              )
            }
          />

          <StatusModal
            resetForm={this.statusModalResetForm}
            isOpen={this.props.isStatus}
            id={this.props.id}
            stateName={this.props.stateName}
            value={this.props.value}
            prevState={this.props.prevState}
            nextState={this.props.nextState}
            rowIndex={this.props.rowIndex}
            field={this.props.field}
            setOpenStatusDialog={this.openStatusDialog}
            updateLeak={this.updateLeakFromDialog}
            updateMobileAlert={this.updateMobileAlertFromDialog}
            leaksIds={this.props.leaksIds}
            selectedFeature={this.props.selectedFeature}
            onEdit={this.statusModalEditComment}
            onCopy={this.statusModalCopyComment}
            alertsContextMenu={this.props.alertsContext}
            mobileAlertContext={this.props.mobileAlertContext}
            path={this.props.path}
          />

          {this.state.popupAlert && <PopupAlert {...this.state.popupAlert} />}

          {this.props.path === 'sensors' && (
            <SensorsActivityModal
              close={this.closeSensorsActivityModal}
              {...this.props.isSensorsActivityState}
              loadData={this.getSensorsActivityData}
              selectedProject={this.props.selectedProjectItem}
              dmasList={this.props.dmas}
              user={user}
            />
          )}

          <SensorsTechnicianModal
            close={this.closeSensorsTechnicianModal}
            {...this.props.isSensorsTechnicianState}
            loadData={this.getTechnicianActivityData}
            selectedProject={this.props.selectedProjectItem}
            dmasList={this.props.dmas}
            user={user}
          />
          <CreateMobileAlertModal
            optionList={optionList}
            resetForm={this.createMobileAlertModalResetForm}
            isOpen={this.state.isAddMobileAlertOpen}
            setOpenStatusDialog={(isOpen) => this.setState({ isAddMobileAlertOpen: isOpen })}
            options={optionList.options}
            createNewMobileAlert={actionsMobileAlerts.createNewMobileAlert}
            selectedFeature={this.props.selectedFeature}
            checkedSamples={this.props.checkedSamples}
            displayItems={this.props.displayItems}
          // onEdit={this.statusModalEditComment}
          // onCopy={this.statusModalCopyComment}
          />

          {this.props.path === 'mobile' && (
            <IQ100BReportsModal
              close={this.closeIQ100BReportsModal}
              {...this.props.isIQ100BMobileReportsState}
              // loadData={this.getSensorsActivityData}
              selectedProject={this.props.selectedProjectItem}
            />
          )}
        </div>
      </section>
    );
  }
}

Page.contextTypes = {
  t: PropTypes.func.isRequired,
};

Page.propTypes = {
  dispatch: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  lastUpdated: PropTypes.number,
  leaks: PropTypes.array.isRequired,
  selectedFeature: PropTypes.string,
  selectedProject: PropTypes.string.isRequired,
};

//Function that automatically created by Redux, Return object with key,value (props)
function mapStateToProps(state) {
  //Takes the data from Redux Store (not 100%)

  const {
    selectedProject,
    leaksByProject,
    projectsList,
    optionList,
    filters,
    user,
    customers,
  } = state.leaksList;
  // Equal to (for example) const sort = leaksByProject[selectedProject].sort;
  const {
    isFetching,
    lastUpdated,
    // "items: leaks" equal to: "const leaks = leaksByProject[selectedProject].items;"
    items: leaks,
    iconFeatures,
    sort,
    history,
    files,
    sensors,
    iq100bMobileRepors,
    sops,
    noiseSamples,
    iQuariusPublishedSamples,
    waterMeterAlerts,
    couples,
    assessments,
    couplePath,
    linkedSensorsPaths,
    couplesCoveragePaths,
    details,
    leakCouple,
    samples,
    coupleSamples,
    log,
    leaksIds,
    mobileDisplayMode,
    leakGrowth: leakGrowthData,
  } = leaksByProject[selectedProject] || {
    selectedProject: selectedProject,
    filters: [],
    isFetching: true,
    items: [],
    iconFeatures: [],
    optionList: {},
    sort: { init: true },
    history: {},
    files: {},
    details: {},
    sensors: {
      isFetching: false,
      sensors: [],
      sensorsIcons: [],
      logs: [],
      totalLogsCount: 0,
      bits: [],
      totalBitsCount: 0,
      details: {},
    },


    iq100bMobileRepors: {
      activity: {
        isVisible: false,
      },
    },
    sops: { isFetching: false, sops: [], sopsIcons: [] },
    noiseSamples: {
      isFetching: false,
      noiseSamples: [],
      noiseSamplesIcons: [],
    },
    iQuariusPublishedSamples: {
      isFetching: false,
      iQuariusSamples: [],
      iQuariusSamplesIcons: [],
    },
    waterMeterAlerts: { isFetching: false, meterAlerts: [], meterIcons: [] },
    couplePath: { isFetching: false, path: [] },
    linkedSensorsPaths: { isFetching: false, paths: [] },
    couplesCoveragePaths: { isFetching: false, paths: [] },
    couples: {},
    leakCouple: {},
    user: {},
    path: '',
    log: {},
    samples: {},
    coupleSamples: {},
    leaksIds: [],
    leakGrowth: [],
  };

  const { filters: valveFilters, defFilters: valveDefFilters } = state.valve;

  const { displayAssessments } = state.leaksList.leaksByProject;
  const { selectedManageItem, managementContext } = state.manage;
  const { users } = state.management;

  let path = state.routing.locationBeforeTransitions.pathname.replace('/', '');
  path = path || 'alerts';

  const isSensorsActivityState = sensors.activity ? sensors.activity : {};
  const isSensorsTechnicianState = sensors.technician ? sensors.technician : {};
  const isIQ100BMobileReportsState = {
    ...state.leaksList.leaksByProject.iq100bMobileRepors.activity,
    users,
  }; // iq100bMobileRepors.activity ? iq100bMobileRepors.activity : {};
  const lang = state.i18nState.lang;
  const projects = projectsList.items || [];
  const { projectIndexMap } = projectsList;
  const selectedProjectItem = projects[projectIndexMap[selectedProject]];
  const projectGraphsUnits =
    selectedProjectItem != null
      ? selectedProjectItem.UseFeetInMatlabGraphs
        ? 'ft'
        : 'm'
      : 'm';

  const iQuariusSamples = state.iQuarius.samples;
  const selectedTaskId = state.iQuarius.selectedTaskId;

  const { relatedItem, selectedAssessment, selectedSensorSample } =
    leaksByProject;

  let { selectedFeatureType } = leaksByProject;

  const mobileMode = state.mobile.modeState.mode;

  if (path === 'mobile' && state.mobile.tasks.selectedTask) {
    selectedFeatureType = 'mobileTask';
  }

  if (mobileMode == 'Alerts') {

    if (path === 'mobile' && state.mobile.alerts.selectedAlert) {
      selectedFeatureType = 'mobileAlert';
    }
  } else if (mobileMode == 'Tasks') {
    if (path === 'mobile' && state.mobile.tasks.selectedTask) {
      selectedFeatureType = 'mobileTask';
    }
  } else if (mobileMode == 'Devices') {
    if (path === 'mobile' && state.mobile.devices.selectedDeviceBist) {
      selectedFeatureType = 'mobileTask';
    }
  }

  const alertsContext = state.leaksList.pressure.context;
  const mobileAlertContext = state.mobile.modeState.mode;
  const dmas = state.leaksList.dmas.dmasList;
  let selectedFeature; // = (path === 'alerts' && alertsContext === 'noise') ? state.noiseAlerts.selected.ID : state.leaksList.leaksByProject.selectedFeature;
  switch (path) {
    case 'alerts': {
      switch (alertsContext) {
        case 'noise':
          selectedFeature = state.noiseAlerts.selected.ID;
          break;
        default:
          selectedFeature = state.leaksList.leaksByProject.selectedFeature;
          break;
        case 'valve':
          selectedFeature = state.valve.selectedAlert?.id;
          break;
      }
      break;
    }

    case 'mobile': {
      let context = state.mobile.modeState.mode;
      if (context == 'Tasks') {
        selectedFeature = state.mobile.tasks.selectedTask.length
          ? state.mobile.tasks.selectedTask
          : [];
      }

      if (context == 'Alerts') {
        selectedFeature = state.mobile.alerts.selectedAlert
          ? state.mobile.alerts.selectedAlert
          : {};
      }

      if (context == 'Devices') {
        selectedFeature = state.mobile.devices?.selectedDeviceBist.length
          ? state.mobile.devices.selectedDeviceBist
          : [];
      }
      break;
    }

    default: {
      selectedFeature = state.leaksList.leaksByProject.selectedFeature;
      break;
    }
  }

  const selectedNoiseAlert = state.noiseAlerts.selected;
  const planningState = state.planning;
  const units = state.local.units;
  const timeZone = state.local.timeZone;
  const pipesSource = state.local.pipesSource;
  const coverageSource = state.local.coverageSource;
  const sensorsGraphsDisplay = state.local.sensorGraphesDisplay;
  const selectedSensorLog = state.leaksList.leaksByProject.selectedSensorLog;
  const selectedSensorBit = state.leaksList.leaksByProject.selectedSensorBit;
  const sensorsHistory = sensors && sensors.history ? sensors.history : [];
  let sensorDetails =
    sensors != null && sensors.details != null ? sensors.details : {};
  const noiseAlertCount =
    sensors && sensors.noiseAlertCount
      ? sensors.noiseAlertCount
      : NOISE_ALERTS_COUNT;

  const calculatedTotalLengthTable =
    state.leaksList.projectsList.calculatedTotalLengthTable;

  const sensorNoiseSamples = sensors.details.details || {};
  const totalNoiseSamplesCount = sensors.details.totalRows;

  const installContext = state.install.installContext;
  const installMode = state.install;
  const mobileContext = state.mobile.modeState.mode;
  const mobileState = state.mobile;
  const isPCA = state.temp.pcaOpen;
  const pcaItem = state.temp.tempPCA;

  const { pcaLeakGrowthOpen, id: openAlertGrowthId } = state.temp;

  const id = state.temp.id;
  const stateName = state.temp.stateName;
  const value = state.temp.value;
  const prevState = state.temp.prevState;
  const nextState = state.temp.nextState;
  const rowIndex = state.temp.rowIndex;
  const field = state.temp.field;
  const isStatus = state.temp.statusOpen;

  let indexMap;
  let alertsIndexMap =
    (leaksByProject[selectedProject] &&
      leaksByProject[selectedProject].indexMap) ||
    {};
  const distanceFactor = state.local.factor[units];
  let assessmentsAlerts = {};

  if (!Array.isArray(selectedFeature) && selectedFeature) {
    assessmentsAlerts = assessments[selectedFeature];
    //Object.assign(assessmentsAlerts, assessments[selectedFeature]);
  }

  let projectName = '';
  let samplesData = {};
  let coupleSamplesData = Object.assign({}, coupleSamples);
  let samplesPage = 0;

  switch (path) {
    case 'alerts':
      indexMap =
        (leaksByProject[selectedProject] &&
          leaksByProject[selectedProject].indexMap) ||
        {};
      Object.assign(samplesData, samples);
      if (
        leaksByProject[selectedProject] &&
        leaksByProject[selectedProject].samples &&
        leaksByProject[selectedProject].samples[selectedFeature]
      ) {
        samplesPage =
          leaksByProject[selectedProject].samples[selectedFeature].pageIndex;
      }
      break;
    case 'sensors':
      indexMap = sensors.indexMap || {};
      if (selectedFeature) {
        const sensor = sensors.sensors[indexMap[selectedFeature]];
        sensorDetails = Object.assign(sensorDetails, sensor);
      }
      break;
    case 'couples':
      if (displayAssessments && selectedFeature) {
        indexMap = assessments[selectedFeature].indexMap || {};
      } else {
        indexMap = couples.indexMap || {};
      }
      break;
    case 'manage':
      switch (managementContext) {
        case 'Customers':
          indexMap = customers.indexMap || {};
          break;
        case 'Projects':
          indexMap = projectsList.projectIndexMap || {};
          break;
        case 'Users':
          indexMap = state.management.usersIndexMap || {};
          break;
      }
      break;
    default:
      break;
  }


  let center = { init: false };
  if (projects.length && selectedProject) {
    const id = projectIndexMap[selectedProject];
    if (id != null) {
      center = {
        init: true,
        lat: projects[id].RefPointLat,
        lng: projects[id].RefPointLng,
      };
      projectName = projects[id].ShortName;
    }
  }

  return {
    alertsContext,
    mobileAlertContext,
    lang,
    selectedProject,
    projectGraphsUnits,
    selectedFeature,
    selectedFeatureType,
    selectedSensorLog,
    selectedSensorBit,
    selectedNoiseAlert,
    relatedItem,
    selectedSensorSample,
    selectedAssessment,
    leaks,
    isFetching,
    lastUpdated,
    iconFeatures,
    projects,
    selectedProjectItem,
    optionList,
    sort,
    history,
    files,
    sensors,
    sensorsHistory,
    sensorDetails,
    noiseAlertCount,
    sops,
    noiseSamples,
    iQuariusPublishedSamples,
    waterMeterAlerts,
    assessmentsAlerts,
    displayAssessments,
    couples,
    couplePath,
    linkedSensorsPaths,
    couplesCoveragePaths,
    details,
    leakCouple,
    filters,
    user,
    path,
    indexMap,
    alertsIndexMap,
    log,
    center,
    samples: samplesData,
    coupleSamples: coupleSamplesData,
    units,
    distanceFactor,
    timeZone,
    pipesSource,
    coverageSource,
    sensorsGraphsDisplay,
    // projectName,
    samplesPage,
    selectedManageItem,
    managementContext,
    customers: customers.items,
    users,
    logo: user.logo,
    planningState,
    installContext,
    installMode,
    mobileContext,
    mobileState,
    iQuariusSamples,
    selectedTaskId,
    isPCA,
    pcaLeakGrowthOpen,
    openAlertGrowthId,
    leakGrowthData,
    calculatedTotalLengthTable,
    sensorNoiseSamples,
    totalNoiseSamplesCount,
    isStatus,
    id,
    leaksIds,
    stateName,
    value,
    prevState,
    nextState,
    rowIndex,
    field,
    pcaItem,
    isSensorsActivityState,
    isSensorsTechnicianState,
    dmas,
    isIQ100BMobileReportsState,
    valveFilters,
    valveDefFilters,
    checkedSamples: state.mobile.samples.checkedSamples,
    displayItems: state.mobile.samples.displayItems,
  };
}

export default connect(mapStateToProps)((props) => (
  <GoogleSheetsContext>
    <Page {...props} />
  </GoogleSheetsContext>
));
